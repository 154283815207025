@import "@/styles/_variables.scss";





































































































































































































































































































































































































.tooltip {
  color: #e69393;
}
.select-options {
  padding-top: 5px;
  padding-bottom: 5px;
}
