@import "@/styles/_variables.scss";















































































































































































































































.tooltip {
  color: #e69393;
}
.uk-button-secondary {
  background-color: #000;
  color: #fff;
}
